import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import { task } from 'ember-concurrency';
import moment from 'moment-timezone';

@classic
export default class MealPlanningTemplateController extends Controller {
  @service
  notify;

  /** @type {?MealPlanningTemplate} */
  selectedTemplate = null;

  activeTab = 'Plan';

  /** @type {?MealPlanningInstance} */
  @alias('model.instanceToPublish')
  instanceToPublish;

  queryParams = ['activeTab'];

  /** @type {Client} */
  @alias('model.client')
  client;

  @alias('model.admins')
  admins;

  @alias('selectedTemplate.orderTemplate.content')
  orderTemplate;

  @alias('client.fragmentDeliveryLocations')
  locations;

  @computed('client.fragmentDeliveryContacts', 'client.fragmentBillingContact')
  get contacts() {
    const fragmentBillingContact = this.get('client.fragmentBillingContact');
    const fragmentDeliveryContacts = this.get('client.fragmentDeliveryContacts').toArray();

    if (fragmentBillingContact) {
      fragmentDeliveryContacts.push(fragmentBillingContact);
    }

    return fragmentDeliveryContacts;
  }

  @task(function* (templateToSelect) {
    if (templateToSelect.errors.length) {
      templateToSelect.rollbackAttributes();
    }
    yield templateToSelect.reload();
    this.set('selectedTemplate', templateToSelect);
  })
  selectTemplateTask;

  @task(function* () {
    const selectedTemplate = this.store.createRecord('meal-planning-template', {
      name: 'New Template',
      defaultNumberOfPeople: 25,
      isRecurring: true,
      syncToSfdc: false,
      client: this.get('client'),
      area: this.get('client.defaultArea'),
      days: []
    });

    selectedTemplate.get('plan').set('contact', this.get('contacts.firstObject').copy());
    selectedTemplate.get('plan').set('location', this.get('locations.firstObject').copy());

    this.set('selectedTemplate', selectedTemplate);

    yield selectedTemplate.save();
    yield selectedTemplate.get('orderTemplate');
  })
  createTemplateTask;

  @action
  createTemplate() {
    const selectedTemplate = this.store.createRecord('meal-planning-template', {
      name: 'New Template',
      defaultNumberOfPeople: 25,
      client: this.get('client'),
      days: []
    });

    selectedTemplate.get('plan').set('contact', this.get('contacts.firstObject').copy());
    selectedTemplate.get('plan').set('location', this.get('locations.firstObject').copy());

    this.set('selectedTemplate', selectedTemplate);
  }

  @action
  saveMealPlanningInstance() {
    const template = this.get('selectedTemplate');
    const instance = this.get('instanceToPublish');

    if (!this.client.defaultContact) {
      this.notify.error('This client requires a contact.');

      return;
    }

    instance.setProperties({
      name: template.get('name'),
      plan: template.get('plan').copy(),
      client: this.get('client'),
      area: this.get('client.defaultArea'),
      mealPlanningTemplate: template
    });

    return instance.save().then((instance) => {
      // need to set a new instance else we're just sending a PATCH
      const newInstance = this.store.createRecord('mealPlanningInstance', {
        startOfWeek: instance.get('startOfWeek')
      });

      return this.set('instanceToPublish', newInstance);
    });
  }

  @action
  handleStartOfWeekPick(selectedDate) {
    const instance = this.get('instanceToPublish');
    const startOfWeek = moment(selectedDate).isoWeekday(1).toDate();

    instance.set('startOfWeek', startOfWeek);
  }

  @action
  handleOrderFormDidSubmit() {
    this.get('notify').success('Order template saved!');
  }

  @action handleDidFail(response) {
    this.get('notify').error(response?.errors?.firstObject?.title);
  }
}
